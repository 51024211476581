.App {
  text-align: center;
}

html{
  font-size: 18px;
  font-family: "Work Sans";
}
input,p,span,h1,h2,h3,h4,h5,h6,li,a{
  font-family: "Work Sans";
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.black {
  background-color: #1A1A1A;
  color: #a1a1a1!important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.rr1{
  text-align: right;
}
.wiht {
  color: #a1a1a1!important;
  text-decoration: initial!important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.mainobr{
  height: 70vh;
  width: 100%;
  background-size: cover;
  background-position: center center;

}
.fl{
  display: flex;
}
.cl{
  align-self: center;
  margin: 0 auto;
}
.odsmin {
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
  border-top: 1px solid #cccccc;
}
.desnone .col-6{
  text-align: center;
}
.now{
  width: initial;
}
.bolder{
font-family: "Work Sans";
}
.mini{
  color: #053975!important;
}
.headline{
  color: #053975!important;
}
.odsmore {
  margin-top: 90px;
}
.ods {
  margin-top: 60px;
  margin-bottom: 60px;
}
.subheadline {
  color: #777;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Work Sans";
  font-size: 1em;

}

.headline{
  font-family: "Work Sans";
  font-weight: 700;
}
.obr_ban {
  height: 360px;
  width: 100%;
}
.contain {
  background-size: contain;
  background-repeat: no-repeat;
}
.blue{

  padding-top: 40px;
  padding-bottom: 80px;
}
input[type='text'], input[type='email'], input[type='tel'], textarea, .area{
  border-radius: 0;
  border: 0;
  border-radius: 0px;
  border-bottom: 1px solid #ddd;
}
*:focus{
  outline: intial!important;
}
.odsmore2 {
  margin-top: 120px;
}
.headline {
  color: rgb(94, 157, 215)!important;
  font-size: 2.5em;
  font-weight: 700;
}
.center {
  text-align: center;
}

.paragraph {
  color: #777;
  font-weight: 400;
  font-size: 1em;
  text-align: justify;
  font-family: "Work Sans";
}
.cookieConsent div{
  font-size: 14px;
}
.cookieConsent div a{
  color: white;

}
.headline {
  color: #000000;
  font-size: 2.5em;
  font-weight: 700;
}
.obrods{
  padding-top: 24%;
}
.nadpisobr{
  color: white;
  font-family: "Work Sans";
  font-size: 3em;
  font-weight: 700;
}

.App-link {
  color: #61dafb;
}
.mini{
  font-size: 1.5em;
}
.stovka{
  width: 100%;
}
.fotsect img{
  width: 40px;
  margin-bottom: 15px;
}
.mbt{
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.fotsect a{
  color: white;
  text-decoration: initial;
}
.area{
  height: 300px!important;
  resize: initial;
}
.error{
  color: darkred;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-top: 10px;
  font-size: 0.8em;
  font-family: "Work Sans";
}
.hide{
  display: none;

}
.postup h6{
  font-weight: 700;
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
}
.postup{
  margin-bottom: 60px;
}
.postup .col-md-6{
  margin-top: 15px;
  margin-bottom: 15px;
}
a{
  color: black;
  text-decoration: underline;
}
.rr{
  float: right;
}
.butonsend{
  background-color:rgb(94, 157, 215)!important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  border: 0;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto;
}
.butonsend:hover{
  background-color: #e0a30c;
}
.smaller{
  display: none;
}
.item{
  display: inline-block;
  margin-right: 15px;
}
.item.btn{
  background-color: #5e9dd7!important;
  border: 0;
  color: white;
  border-radius: 50px;
  border: 2px solid #5e9dd7!important;

}
.item.btn.log{
  background-color: white!important;
  border: 2px solid #5e9dd7!important;
  color: #5e9dd7!important;
  border-radius: 50px;
}
.item.btn.log a{

  color: #5e9dd7!important;

}
.mainobr{
  margin-top: 60px;
}
div.menu{
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 9999999999;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
.item.btn a{
  color: white;
  font-weight: 700;
}
.logo{
  width: 220px;
}
.menu .item{
  list-style-type: none;
  text-decoration: initial;
}
.menu .item a{
  text-decoration: initial;
}
.navbar{
  padding-bottom: 0;
  margin-bottom: 0;
  z-index: 9999999999!important;
}
.right1{
  text-align: right;
}
iframe{
  width: 100%;
  height: 300px;
}
label{
  font-weight: 700;
}
.fotsect{
  padding-top: 35px;
  padding-bottom: 25px;
  text-align: center;
  background-color: #000000;
  color: white;
  font-family: "Work Sans";
}
.order-success .nadpis{
  font-family: "Work Sans";
  color: #5e9dd7!important;
  font-weight: 700;
  text-transform: uppercase;
}
.order-success .parag{
  font-family: "Work Sans";
  text-transform: uppercase;
}
input::placeholder{
  color: rgba(0,0,0,0.3)!important;

}
.headline.center{
  color: black!important;
}
.centerize{
  text-align: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1199px) {
  #primary-menu li{
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 200px;
    text-align: center;

  }
  .navbar-toggler-icon,.navbar-toggler{
    display: none;
  }
  .right{
    float: right;
    background-color: white;
    margin-right: 10px;
    border-radius: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-decoration: initial;
    padding-left: 7px;
    font-family: "Work Sans";

    padding-right: 7px;
  }
  .right:hover{
    text-decoration: initial;
    color: black;
  }
  .bl{
    color: white!important;
    background-color: #5e9dd7!important;
  }
  .smaller{
    display: block;
    background-color: #e0a30c;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #primary-menu{
    padding-left: 0;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 992px){
  .obrods{
    padding-top: 38%;
  }
}

@media screen and (max-width: 768px){
  .rr1,.ll1 {
    text-align: center;
  }
  .obrods{
    padding-top: 48%;
  }
}
.desnone{
  display: none;
}

@media screen and (max-width: 768px) {
  .mobnone{
    display: none;
  }
  .desnone{
    display: block;
  }
  .col-4,.col-8{
    font-size: 14px;
  }
}

@media screen and (max-width: 480px){
  .obrods{
    padding-top: 55%;
  }
  .obr_ban{
    height: 200px;
    background-size: cover;
  }
  input[type='submit']{
    width: 100%;
  }
  .postup{
    text-align: center;
  }
}
@media screen and (max-width: 400px){
  .dopln{
    font-size: 14px;
  }
  .obrods{
    padding-top: 65%;
  }
  img.cl{
    width: 35px;
  }
}
@media screen and (max-width: 330px){

  .obrods{
    padding-top: 50%;
  }
  .navbar{
    margin-left: 0;
    padding-left: 0;

  }
  .logo{
    width: 170px;
  }

}